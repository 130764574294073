@font-face {
	font-family: "NoirdenSans";
	src: url("./fonts/NoirdenSans/WebFont/Noirden-Thin.woff") format("woff");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "NoirdenSans";
	src: url("./fonts/NoirdenSans/WebFont/Noirden-ThinOblique.woff")
		format("woff");
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "NoirdenSans";
	src: url("./fonts/NoirdenSans/WebFont/Noirden-ExtraLight.woff") format("woff");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "NoirdenSans";
	src: url("./fonts/NoirdenSans/WebFont/Noirden-ExtraLightOblique.woff")
		format("woff");
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "NoirdenSans";
	src: url("./fonts/NoirdenSans/WebFont/Noirden-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "NoirdenSans";
	src: url("./fonts/NoirdenSans/WebFont/Noirden-LightOblique.woff")
		format("woff");
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "NoirdenSans";
	src: url("./fonts/NoirdenSans/WebFont/Noirden-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "NoirdenSans";
	src: url("./fonts/NoirdenSans/WebFont/Noirden-RegularOblique.woff")
		format("woff");
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "NoirdenSans";
	src: url("./fonts/NoirdenSans/WebFont/Noirden-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "NoirdenSans";
	src: url("./fonts/NoirdenSans/WebFont/Noirden-SemiBoldOblique.woff")
		format("woff");
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "NoirdenSans";
	src: url("./fonts/NoirdenSans/WebFont/Noirden-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "NoirdenSans";
	src: url("./fonts/NoirdenSans/WebFont/Noirden-BoldOblique.woff")
		format("woff");
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}
