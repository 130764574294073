@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body {
		font-family: "NoirdenSans", sans-serif;
		letter-spacing: 0.05em;
	}

	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;

		--card: 0 0% 98.4%;
		--card-foreground: 222.2 84% 4.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--primary: 221.2 83.2% 53.3%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--muted: 218, 5%, 84%;
		--muted-foreground: 218, 5%, 44%;

		--accent: 220, 43%, 95%;
		--accent-foreground: 220, 43%, 45%;

		--destructive: 0 88% 46.3%;
		--destructive-foreground: 0 0% 100%;

		--border: 213, 14%, 88%;
		--input: 214.3 31.8% 91.4%;
		--ring: 225 100% 54.5%;

		--radius: 0.5rem;

		--sidebar-background: 0 0% 100%;
		--sidebar-foreground: 222.2 84% 4.9%;
		--sidebar-border: 214.3 31.8% 91.4%;
		--sidebar-primary: 221.2 83.2% 53.3%;
		--sidebar-primary-foreground: 210 40% 98%;
		--sidebar-secondary: 210 40% 96.1%;
		--sidebar-secondary-foreground: 222.2 47.4% 11.2%;
		--sidebar-muted: 210 40% 96.1%;
		--sidebar-muted-foreground: 215.4 16.3% 46.9%;
		--sidebar-accent: 210 40% 96.1%;
		--sidebar-accent-foreground: 222.2 47.4% 11.2%;
		--sidebar-destructive: 0 84.2% 60.2%;
		--sidebar-destructive-foreground: 210 40% 98%;
		--sidebar-ring: 215 20.2% 65.1%;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;

		--card: 222.2 84% 6.5%;
		--card-foreground: 210 40% 98%;

		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;

		--primary: 217.2 91.2% 59.8%;
		--primary-foreground: 222.2 47.4% 11.2%;

		--secondary: 217.2 32.6% 17.5%;
		--secondary-foreground: 210 40% 98%;

		--muted: 237, 9%, 16%;
		--muted-foreground: 237, 9%, 56%;

		--accent: 226, 30%, 17%;
		--accent-foreground: 226, 30%, 67%;

		--destructive: 0 88% 46.3%;
		--destructive-foreground: 0 0% 100%;

		--border: 236, 15%, 20%;
		--input: 217.2 32.6% 17.5%;
		--ring: 210 74.7% 62%;

		--sidebar-background: 222.2 84% 4.9%;
		--sidebar-foreground: 210 40% 98%;
		--sidebar-border: 217.2 32.6% 17.5%;
		--sidebar-primary: 217.2 91.2% 59.8%;
		--sidebar-primary-foreground: 222.2 47.4% 11.2%;
		--sidebar-secondary: 217.2 32.6% 17.5%;
		--sidebar-secondary-foreground: 210 40% 98%;
		--sidebar-muted: 223 47% 11%;
		--sidebar-muted-foreground: 215.4 16.3% 56.9%;
		--sidebar-accent: 217.2 32.6% 17.5%;
		--sidebar-accent-foreground: 210 40% 98%;
		--sidebar-destructive: 0 62.8% 30.6%;
		--sidebar-destructive-foreground: 0 85.7% 97.3%;
		--sidebar-ring: 217.2 32.6% 17.5%;
	}
}

@layer base {
	* {
		@apply border-border;
	}

	body {
		@apply bg-background text-foreground;
	}
}

@layer components {
	.markdown-content {
		@apply px-4 py-8;
	}

	.markdown-content h1 {
		@apply text-4xl font-bold mb-6 text-gray-900 dark:text-gray-100;
	}

	.markdown-content h2 {
		@apply text-3xl font-semibold mt-8 mb-4 text-gray-800 dark:text-gray-200;
	}

	.markdown-content h3 {
		@apply text-2xl font-medium mt-6 mb-3 text-gray-700 dark:text-gray-300;
	}

	.markdown-content p {
		@apply text-base mb-4 text-gray-600 dark:text-gray-200;
	}

	.markdown-content ul,
	.markdown-content ol {
		@apply mb-4 pl-6;
	}

	.markdown-content li {
		@apply mb-2;
	}

	.markdown-content a {
		@apply text-blue-600 dark:text-blue-400 hover:underline;
	}

	.markdown-content code {
		@apply bg-gray-100 dark:bg-gray-800 rounded px-1 py-0.5 text-sm font-mono;
	}

	.markdown-content pre {
		@apply bg-gray-100 dark:bg-gray-800 rounded p-4 mb-4 overflow-x-auto;
	}

	.markdown-content blockquote {
		@apply border-l-4 border-gray-300 dark:border-gray-700 pl-4 italic my-4;
	}

	.markdown-content img {
		@apply max-w-full h-auto rounded-lg shadow-md my-4;
	}

	.markdown-content table {
		@apply w-full border-collapse mb-4;
	}

	.markdown-content th,
	.markdown-content td {
		@apply border border-gray-300 dark:border-gray-700 px-4 py-2;
	}

	.markdown-content th {
		@apply bg-gray-100 dark:bg-gray-800 font-semibold;
	}
}

input[type="time"] {
	position: relative;
	width: 100%;
}

input[type="time"]::-webkit-calendar-picker-indicator {
	position: absolute;
	top: 0;
	left: -10px;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
}

.select-no-arrow {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: none;
}

.cl-otpCodeFieldInput {
	border-width: 1px !important;
}

/* HTML: <div class="loader"></div> */
.loader {
	width: 60px;
	aspect-ratio: 1;
	display: grid;
	grid: 50% / 50%;
	color: #3778e1;
	--_g: no-repeat linear-gradient(currentColor 0 0);
	background: var(--_g), var(--_g), var(--_g);
	background-size: 50.1% 50.1%;
	animation: l6-0 1.5s infinite steps(1) alternate, l6-0-0 3s infinite steps(1);
}
.loader::before {
	content: "";
	background: currentColor;
	transform: perspective(150px) rotateY(0deg) rotateX(0deg);
	transform-origin: bottom right;
	animation: l6-1 1.5s infinite linear alternate;
}
@keyframes l6-0 {
	0% {
		background-position: 0 100%, 100% 100%, 100% 0;
	}
	33% {
		background-position: 100% 100%, 100% 100%, 100% 0;
	}
	66% {
		background-position: 100% 0, 100% 0, 100% 0;
	}
}
@keyframes l6-0-0 {
	0% {
		transform: scaleX(1) rotate(0deg);
	}
	50% {
		transform: scaleX(-1) rotate(-90deg);
	}
}
@keyframes l6-1 {
	16.5% {
		transform: perspective(150px) rotateX(-90deg) rotateY(0deg) rotateX(0deg);
		filter: grayscale(0.8);
	}
	33% {
		transform: perspective(150px) rotateX(-180deg) rotateY(0deg) rotateX(0deg);
	}
	66% {
		transform: perspective(150px) rotateX(-180deg) rotateY(-180deg)
			rotateX(0deg);
	}
	100% {
		transform: perspective(150px) rotateX(-180deg) rotateY(-180deg)
			rotateX(-180deg);
		filter: grayscale(0.8);
	}
}
